@font-face {
    font-family: "Dolphins";
    src: local("dolphins"),
        url("../assets/fonts/dolphins.ttf") format("truetype");
}

.all-buttons {
}

.user-button,
.settings-button {
    background-image: linear-gradient(to bottom, rgb(93, 93, 93), #464646);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 10px;
    margin: 10px 3px 10px 15px;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    align-items: center;
}

.user-button:hover,
.settings-button:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.user-button:active,
.settings-button:active {
    transform: translateY(0);
    background-image: linear-gradient(to top, rgb(68, 68, 68), #313131);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.title-font {
    font-family: "Dolphins";
    color: white;
    display: flex;
    align-items: flex-start;
    //font-size: 5vw;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 25px 0px 10px;
}

.header {
    //background-color: black;
    background-image: linear-gradient(to top, #2f2e2e, #2f2f2f);
    background-clip: content-box, padding-box;
    display: flex;
    align-items: flex-end;
    max-height: 80px;
    width: auto;
}

.user-button {
    display: flex;
    flex-direction: row;
    padding-right: 12;
    border-radius: 30px;
}

.user-button-fa-icon {
    padding-right: 8px;
}

.settings-button {
    border-radius: 30px;
    overflow: hidden;
    margin: 10px 15px 10px 5px;
}

.settings-button-fa-icon {
    padding-right: 8px;
    padding-left: 8px;
}

.trailing-buttons {
    display: flex;
    //min-width: 1000px;
    margin: 0;
}
