.hidden {
    display: none;
}

.sidebar {
    display: flex;
    flex-direction: column;
    width: 340px;
    min-height: 90vh;

    background-color: #eee;
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 20px 0px;
    //padding: 0px;
}

.week-header-title {
    padding-left: 20px;
    margin-bottom: 10px;
    color: grey;
}

.button {
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.211);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 18px 10px;
    margin: 10px 10px;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    align-items: center;
    align-content: center;
}

.sidebar-icon {
    padding: 0px 10px 0px 12px;
}

.button.pick {
    background-image: linear-gradient(to top, rgb(70, 166, 62), #8ce36f);
    margin-bottom: auto;
}

.button.help {
    background-image: linear-gradient(to top, #5c5c5c, #999999);
    margin-bottom: 20px;
}

.button:hover {
    transition: 0.1s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.button:active {
    transition: 0.3s;
}

.button:active.pick {
    background-image: linear-gradient(to bottom, rgb(66, 128, 61), #67ac51);
    box-shadow: 1px 1px 1px rgba(74, 182, 62, 1);
}

.button:active.help {
    background-image: linear-gradient(to bottom, #2d2d2d, #565656);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.divider-line {
    height: 2px;
    background-color: #ccc;
    margin: 0 15px;
}

.week-header {
    display: flex;
    flex-direction: row;
}

.button.button-edit {
    margin-left: auto;
    padding: 0px 10px;
    border-radius: 8px;
    background-image: linear-gradient(
        to top,
        rgba(92, 92, 92, 0.403),
        rgba(153, 153, 153, 0.4)
    );
}

.button:active.button-edit {
    background-image: linear-gradient(
        to bottom,
        rgba(92, 92, 92, 0.403),
        rgba(153, 153, 153, 0.4)
    );
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}
