.picker-sidebar {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: auto;
    background-color: #eee;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 10px;
    overflow: hidden;
}

@media (max-width: 800px) {
    .picker-sidebar {
        width: 100px;
    }
}
