.day-container {
    margin-bottom: 1px;
    padding: 0px 15px;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.day-container.selected {
    background-color: rgb(140, 228, 111);
    color: white;
}

.day-label {
    font-size: 20px;
    font-weight: 800;
    padding-right: 4px;
    margin-right: auto;
}

.day-recipe {
    font-family: sans-serif;
    text-align: right;
}
