.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
}

.modal.visible {
    display: block;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    max-height: 80vh;
    height: auto;
    width: 120vh;
    overflow: auto;
}

.close-button {
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.211);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    width: 250px;
    padding: 18px 10px;
    transition: 0.3s;
    background-image: linear-gradient(to top, rgb(70, 166, 62), #8ce36f);
    margin-bottom: auto;
    margin-top: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.close-button:hover {
    transition: 0.1s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.close-button:active {
    transition: 0.3s;
    background-image: linear-gradient(to bottom, rgb(66, 128, 61), #67ac51);
    box-shadow: 1px 1px 1px rgba(74, 182, 62, 1);
}
