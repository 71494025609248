.day-item-recipe-image {
    width: 30%;
    height: 100%;
    align-content: center;
    align-items: center;
    object-fit: scale-down;
}

.day-item-recipe-container {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
        rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: transparent;
    padding: 0x 0px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    min-height: 40px;
    min-width: 100px;
    align-content: center;
    align-items: center;
}

.day-item-recipe-name {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: bold;
    align-content: center;
    align-items: center;
}
