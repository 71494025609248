.login {
    margin: 60px;
}

.button {
    background-image: linear-gradient(to top, rgb(70, 166, 62), #8ce36f);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    transition: transform 0.2s ease-in-out;
}

.button:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.button:active {
    transform: translateY(0);
    background-image: linear-gradient(to bottom, rgb(66, 128, 61), #67ac51);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.padded {
    padding: 10px 0px;
}

.input-container {
    position: relative;
}

.input-text-field {
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f7f7f7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2) inset,
        -2px -2px 5px rgba(255, 255, 255, 0.5) inset;
}

.input-text-field:focus {
    outline: none;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2) inset,
        -2px -2px 5px rgba(255, 255, 255, 0.5) inset, 0 0 5px green;
}

.input-label {
    position: absolute;
    top: -5px;
    left: 10px;
    font-size: 12px;
    color: #999;
}
