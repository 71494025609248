.tab-bar {
    width: 100%;
    background-color: none;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    flex-shrink: 1; /* prevent buttons from shrinking */
    font-size: 16px; /* or whatever size you want */
}

.tab-list {
    display: flex;
}

.tab-item {
    flex: 1;
    text-align: center;
    padding: 15px 20px;
    border-radius: 15px 15px 0 0;
    border-style: solid;
    border-width: 0.5px;
    border-color: rgba(255, 255, 255, 0.171);

    cursor: pointer;
    font-family: sans-serif;
    font-weight: 700;
    background-image: linear-gradient(to top, #494949, #717171);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.211);
}

.tab-item span {
    font-size: 16px;
    color: #fff;
}

.tab-item.active {
    background-image: linear-gradient(to top, rgb(70, 166, 62), #8ce36f);
}

.tab-item.active span {
    color: #fff;
}

.tab-recipe {
    background-image: linear-gradient(to top, #58a64d, #a2df7f);
}

.tab-recipe.active {
    background-image: linear-gradient(to bottom, #2b6022, #76b950);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px 2px inset;
}

.tab-cook {
    background-image: linear-gradient(to bottom, #8c8c8c, #6a6a6a);
}

.tab-cook.active {
    background-image: linear-gradient(to bottom, #4b4b4b, #6c6c6c);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px 2px inset;
}

.tab-fridge {
    background-image: linear-gradient(to top, #8c8c8c, #d3d3d3);
}

.tab-fridge.active {
    background-image: linear-gradient(to bottom, #8c8c8c, #e4e4e4);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px 2px inset;
}

.tab-shopping {
    background-image: linear-gradient(to bottom, #ab794d, #865c2d);
}

.tab-shopping.active {
    background-image: linear-gradient(to bottom, #865c2d, #ab794d);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px 2px inset;
}
