.main-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 20px;
    max-height: 88vh;
    overflow: auto;
}

.main-content h2 {
    margin-bottom: 10px;
}

.main-content img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
}

.ingredients-list {
    display: flex;
    flex-direction: row;
    width: 65vw;
    min-height: 200px;
    overflow: auto;
    padding-bottom: 20px;
}

.ingredients-list-item {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 5px, 5px;
    width: 120px;
    min-width: 120px;
    margin: 8px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
}

.step-header,
.ingredient-header {
    font-weight: 700;
}

.recipe-image {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px,
        rgba(0, 0, 0, 0.1) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
}

.title-row {
    display: flex;
}

.recipe-image-container {
    margin-left: auto;
}

.title-container {
    margin-top: auto;
    margin-bottom: 10px;
}

.horizontal-divider {
    min-height: 2px;
    background-color: #eee;
    margin-top: 0px;
}

.ingredient-image {
    max-width: 64px;
    max-height: 64px;
}

.cook-button {
    min-width: 90px;
    margin-right: 10px;
    background-image: linear-gradient(to top, rgb(70, 166, 62), #8ce36f);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    align-items: center;
    align-content: center;
}

.cook-button:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.cook-button:active {
    transform: translateY(0);
    background-image: linear-gradient(to bottom, rgb(66, 128, 61), #67ac51);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

p.button-text {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 14px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.211);
}

.adjust-button {
    min-width: 90px;
    margin-right: 10px;
    background-image: linear-gradient(to top, rgb(155, 155, 155), #d1d1d1);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 6px;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    align-items: center;
    align-content: center;
}

.fa-shadow {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.211));
}
