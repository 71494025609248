.root-cook-container {
    display: flex;
}

.cook-container {
    padding-top: 30px;
    padding-left: 50px;
    overflow: auto;
    max-height: 88vh;
    width: 100vw;
}

.steps-list {
}

.side-container {
    padding-top: 30px;
    padding-left: 50px;
}

.recipe-image.small {
    width: 64px;
    height: 64px;
    margin-right: 15px;
}

.title-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.step-header-cook {
    font-size: 32px;
    font-weight: 700;
}

.step-contents-cook {
    font-size: 25px;
    margin-bottom: 10vh;
}

.back-button-text {
    margin: 0px;
    margin-left: 10px;
}

.back-button {
    min-width: 90px;
    margin-right: 10px;
    background-image: linear-gradient(to top, #b52b2b, #f07373);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    align-items: center;
    align-content: center;
    display: flex;
    margin-top: 10px;
}

.back-button:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.back-button:active {
    transform: translateY(0);
    background-image: linear-gradient(to bottom, #9f2424, #d76666);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}
