.sidebar-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    background-color: white;
    cursor: pointer;
    height: 60px;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.25) 0px 3px 7px -3px;
}

.sidebar-item:hover {
    background-color: #ddd;
    transition: 0.3s;
}

.sidebar-item img {
    width: 60px;
    height: 60px;

    margin-left: auto;
    margin-right: 0px;
    //padding-left: 10px;
}

.sidebar-item .sidebar-item-content {
    margin-left: 0;
}

.sidebar-item.selected {
    background-color: #ddd;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px inset;
    font-weight: bold;
}

.item-title {
    font-size: medium;
}

.date-box {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-right: 10px;
    font-family: sans-serif;
}

.day-of-week {
    font-size: 12px;
}

.day-of-month {
    font-size: 32px;
}
