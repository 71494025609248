.recipe-tile {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px,
        rgba(0, 0, 0, 0.1) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    overflow: hidden;
    width: 120px;
    margin: 5px;
    height: 180px;
}

.recipe-tile img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.recipe-tile h2 {
    font-size: 1.2rem;
    padding: 10px;
    text-align: center;
}

.recipe-tile-title {
    text-align: center;
    font-size: small;
    padding: 0px 5px;
    font-weight: 700;
    color: rgb(58, 58, 58);
}
