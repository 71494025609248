.recipe-grid {
    display: grid;
    //grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    //margin-top: 10px;
    padding: 15px;
}

.recipe-list {
    display: flex;
    height: 500px;
}

.main {
    flex-grow: 1;
    padding: 20px;
}

@media (min-width: 300px) {
    .recipe-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 500px) {
    .recipe-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 800px) {
    .recipe-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.picker-sidebar {
    display: flex;
    flex-direction: column;
    background-color: #eee;
    //width: 250px;
}

.sidebar.active {
    border-color: blue;
}

.title {
    padding-left: 25px;
}

.vertical-divider {
    width: 2px;
    background-color: #eee;
    margin: 0 15px;
}
